<script setup>
import { onMounted, ref } from "vue";

import AppAlertCard from "@/components/AppAlertCard";
import AppButton from "@/components/AppButton";
import AppFormInput from "@/components/Form/AppFormInput";
import AppCard from "@/components/AppCard";

import { store } from "@/store";

import getFormFields from "@/utils/getFormFields";

// Api
import useApi from "@/composables/useApi";
const { user } = useApi();

const formSent = ref(false);
const loading = ref(false);

const connectionPublicUser = async () => {
  try {
    const data = await user.publicUser();
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

const submitForm = async (e) => {
  try {
    console.log("Submit");
    loading.value = true;

    const fields = getFormFields(e);

    console.log(fields);
    const connection = await connectionPublicUser();
    // console.log(connection);
    const data = await user.sendResetPasswordEmail(fields.email_user);
    // console.log(data);
    formSent.value = true;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = true;
  }
};

onMounted(async () => {
  try {
    store.APP_LOADING = true;
    formSent.value = false;
  } finally {
    store.APP_LOADING = false;
  }
});
</script>

<template lang="pug">
form(@submit.prevent="submitForm" v-if="!formSent")
  AppCard(:title="$t('userOptions.forgotPass')")
    AppFormInput(type="email" :label="$t('lead.email')" name="email_user" required)
    AppButton.mb-0(:loading="loading" :disabled="loading" :label="$t('common.send')")
AppAlertCard(:title="$t('password_reset.valid_email')" :subtitle="$t('password_reset.check_mail')" v-else)
</template>
