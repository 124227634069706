<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import AppRequestForm from "@/components/PasswordReset/AppRequestForm";

import { store } from "@/store";

const route = useRoute();
// const mail_token = ref(null);

onMounted(async () => {
  try {
    // mail_token.value = route.query?.q;
    store.APP_LOADING = true;
  } finally {
    store.APP_LOADING = false;
  }
});
</script>

<template lang="pug">
AppRequestForm()
</template>


